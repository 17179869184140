import React from "react";
import Nav from "../../components/Nav/Nav";
import HeroSection from "../../components/HeroSection/HeroSection";
import Footer from "../../components/Footer/Footer";
import OpportunidadeHero from "../../assets/images/oportunidadehero.webp";
import RecruitmentSection from "./RecruitmentSection";
import GoogleFormEmbed from "../../components/GoogleFormEmbed/GoogleFormEmbed";

const RecruitmentPage: React.FC = () => {
  const googleFormUrl =
    "https://docs.google.com/forms/d/e/1FAIpQLSfggNk0wtoox6X2ILNwbreKpvDvpJsjrIS0QflOK5N1fv88qA/viewform?embedded=true";
  return (
    <>
      <Nav />
      <HeroSection
        title="Opportunidade Ritello"
        subtitle="Junte-se á nossa equipa"
        backgroundImage={OpportunidadeHero}
      />

      <RecruitmentSection />
      <GoogleFormEmbed formUrl={googleFormUrl} />

      <Footer />
    </>
  );
};

export default RecruitmentPage;
