interface Certificate {
  id: string;
  title?: string;
  description?: string;
  img: string;
  isBig?: boolean; // Added to identify the big certificate
}

const certificates: Certificate[] = [
  {
    id: "big_certificate_1",
    img: require("../assets/images/certificates/certificates-main.webp"),
    isBig: true,
  },
  {
    id: "big_certificate_2",
    img: require("../assets/images/certificates/certificado_cruzvermelha.webp"),
    isBig: true,
  },
  {
    id: "big_certificate_3",
    img: require("../assets/images/certificates/certificado_italiano.webp"),
    isBig: true,
  },
  {
    id: "big_certificate_4",
    img: require("../assets/images/certificates/certificado_conformidade.webp"),
    isBig: true,
  },
  {
    id: "big_certificate_5",
    img: require("../assets/images/certificates/Certificado-Ibr.webp"),
    isBig: true,
  },
  {
    id: "cert6",
    img: require("../assets/images/certificates/Certificado3.webp"),
  },
  {
    id: "cert7",
    img: require("../assets/images/certificates/Certificado4.webp"),
  },
  {
    id: "cert8",
    img: require("../assets/images/certificates/Certificado5.webp"),
  },
  {
    id: "cert9",
    img: require("../assets/images/certificates/Certificado6.webp"),
  },
  {
    id: "cert10",
    img: require("../assets/images/certificates/Certificado7.webp"),
  },
];

export default certificates;
