import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./ProductHighlights.module.css";
import products from "../../../data/products";

const ProductHighlights: React.FC = () => {
  const { t } = useTranslation();
  const { lng } = useParams<{ lng: string }>();
  const language = lng || "pt";

  return (
    <section className={styles.productHighlights}>
      <h2 className={styles.productTitle}>{t("product_highlights.title")}</h2>
      <div className={styles.productGrid}>
        {products.map((product) => (
          <Link
            to={`/${language}${t("routes.product")}/${product.id}`}
            key={product.id}
            className={styles.productItem}
          >
            <img
              src={product.img}
              alt={t(`products.${product.id}.name`)}
              className={styles.productImage}
            />
            <h3 className={styles.productName}>
              {t(`products.${product.id}.name`)}
            </h3>
            <p className={styles.productPrice}>
              {t(`products.${product.id}.price`)}
            </p>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default ProductHighlights;
