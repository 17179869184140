// src/pages/SocialResponsibilityPage/SocialResponsibilitySection.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./SocialResponsibilitySection.module.css";
import Foundation1 from "../../assets/images/foundation/foundation1.webp";
import Foundation2 from "../../assets/images/foundation/foundation2.webp";
import Foundation3 from "../../assets/images/foundation/foundation3.webp";
import Foundation4 from "../../assets/images/foundation/foundation4.webp";
import Foundation5 from "../../assets/images/foundation/foundation5.webp";
import Foundation6 from "../../assets/images/foundation/foundation6.webp";
import Foundation7 from "../../assets/images/foundation/foundation7.webp";
import Timeline from "../../components/HistoryComponents/Timeline/Timeline";
import Milestone from "../../components/HistoryComponents/Milestone/Milestone";

const SocialResponsibilitySection: React.FC = () => {
  const { t } = useTranslation();

  const milestones = [
    {
      title: t("social_responsibility.milestone1.title"),
      description: t("social_responsibility.milestone1.description"),
      image: Foundation1,
      isRightAligned: false,
    },
    {
      title: t("social_responsibility.milestone2.title"),
      description: t("social_responsibility.milestone2.description"),
      image: Foundation2,
      isRightAligned: true,
    },
    {
      title: t("social_responsibility.milestone3.title"),
      description: t("social_responsibility.milestone3.description"),
      image: Foundation3,
      isRightAligned: false,
    },
    {
      title: t("social_responsibility.milestone4.title"),
      description: t("social_responsibility.milestone4.description"),
      image: Foundation4,
      isRightAligned: true,
    },
    {
      title: t("social_responsibility.milestone5.title"),
      description: t("social_responsibility.milestone5.description"),
      image: Foundation5,
      isRightAligned: false,
    },
    {
      title: t("social_responsibility.milestone6.title"),
      description: t("social_responsibility.milestone6.description"),
      image: Foundation6,
      isRightAligned: true,
    },
    {
      title: t("social_responsibility.milestone7.title"),
      description: t("social_responsibility.milestone7.description"),
      image: Foundation7,
      isRightAligned: false,
    },
  ];

  return (
    <section className={styles.socialResponsibilitySection}>
      <Timeline>
        {milestones.map((milestone, index) => (
          <Milestone
            key={index}
            title={milestone.title}
            description={milestone.description}
            image={milestone.image}
            isRightAligned={milestone.isRightAligned}
          />
        ))}
      </Timeline>
    </section>
  );
};

export default SocialResponsibilitySection;
