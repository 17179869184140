import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./LanguageSelector.module.css";
import PTFlag from "../../assets/images/language-flags/portugal-flag.webp";
import UKFlag from "../../assets/images/language-flags/uk-flag.webp";
import FRFlag from "../../assets/images/language-flags/france-flag.webp";
import ITFlag from "../../assets/images/language-flags/italy-flag.webp";
import ESFlag from "../../assets/images/language-flags/spain-flag.webp";

const supportedLanguages = ["pt", "en", "fr", "it", "es"];

const languageFlagMap: { [key: string]: string } = {
  pt: PTFlag,
  en: UKFlag,
  fr: FRFlag,
  it: ITFlag,
  es: ESFlag,
};

const LanguageSelector: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { lng } = useParams<{ lng: string }>();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (language: string) => {
    if (language !== lng) {
      i18n.changeLanguage(language);

      const pathSegments = window.location.pathname.split("/");

      // Remove empty strings from segments
      const filteredSegments = pathSegments.filter((segment) => segment !== "");

      // Check if the first segment is a supported language
      if (supportedLanguages.includes(filteredSegments[0])) {
        // Replace the language code
        filteredSegments[0] = language;
      } else {
        // Add the language code at the beginning
        filteredSegments.unshift(language);
      }

      const newPath = "/" + filteredSegments.join("/");
      navigate(newPath);
    }
    setIsOpen(false);
  };

  const currentLanguage = supportedLanguages.includes(lng || i18n.language)
    ? lng || i18n.language
    : "pt"; // Default to 'pt' if undefined or unsupported

  return (
    <div className={styles.languageSelector}>
      <div className={styles.languageDropdown} onClick={toggleDropdown}>
        <div className={styles.languageText}>
          <img
            src={languageFlagMap[currentLanguage] || UKFlag}
            alt={`${currentLanguage.toUpperCase()} flag`}
            className={styles.flagIcon}
          />
          <span className={styles.languageCode}>
            {currentLanguage.toUpperCase()}
          </span>
        </div>
        <FaChevronDown className={styles.languageIcon} />
      </div>
      {isOpen && (
        <div className={styles.languageLinks}>
          <ul>
            {supportedLanguages.map((lang) => (
              <li
                key={lang}
                onClick={() => changeLanguage(lang)}
                className={styles.languageOption}
              >
                <img
                  src={languageFlagMap[lang]}
                  alt={`${lang.toUpperCase()} flag`}
                  className={styles.flagIcon}
                />
                {lang.toUpperCase()}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
