// pages/IronFormPage/IronFormPage.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import Nav from "../../components/Nav/Nav";
import HeroSection from "../../components/HeroSection/HeroSection";
import Footer from "../../components/Footer/Footer";
import GoogleFormEmbed from "../../components/GoogleFormEmbed/GoogleFormEmbed";
import Iron from "../../assets/images/campaigns/FerroIngomarSemFios.webp";
import styles from "./IronFormPage.module.css";

const IronFormPage: React.FC = () => {
  const { t } = useTranslation();
  const googleFormUrl =
    "https://docs.google.com/forms/d/e/1FAIpQLSdrFXwJ-DB1ciT0yUXUqVLtZ8EQ3Db2u5fTV2Lvbx5ancCNfw/viewform?embedded=true"; // Replace with actual Google Form embed URL

  return (
    <>
      <Nav />
      <HeroSection
        title={t("iron_form_page.title")}
        subtitle={t("iron_form_page.subtitle")}
        backgroundImage={Iron}
      />
      <div className={styles.ironFormSection}>
        <GoogleFormEmbed formUrl={googleFormUrl} />
      </div>
      <Footer />
    </>
  );
};

export default IronFormPage;
