import React from "react";
import styles from "./Footer.module.css";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import LivroReclamacoes from "../../assets/images/livroReclamacoes.webp";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const { lng } = useParams<{ lng: string }>();
  const currentLanguage = lng || "pt";

  const links = [
    {
      text: t("footer.privacy_policy"),
      url: `/${currentLanguage}${t("routes.privacy")}`,
    },
    {
      text: t("footer.terms_of_service"),
      url: `/${currentLanguage}${t("routes.terms")}`,
    },
    {
      text: t("footer.contacts"),
      url: `/${currentLanguage}${t("routes.contact")}`,
    },
  ];

  const socialMediaIcons = [
    {
      platform: "Facebook",
      link: "https://www.facebook.com/AspiradoresAgua/",
      icon: <FaFacebook />,
    },
    {
      platform: "Instagram",
      link: "https://www.instagram.com/ritelloportugal/",
      icon: <FaInstagram />,
    },
  ];

  return (
    <footer className={styles.footer}>
      <ul>
        {links.map((link, index) => (
          <li key={index}>
            <a href={link.url}>{link.text}</a>
          </li>
        ))}
      </ul>
      <a
        href="https://www.livroreclamacoes.pt/Inicio/"
        className={styles.livroReclamacoesLink}
        aria-label="Livro de Reclamações"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={LivroReclamacoes} alt="Livro de Reclamações" />
      </a>
      <div className={styles.socialMedia}>
        {socialMediaIcons.map((icon, index) => (
          <a
            key={index}
            href={icon.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {icon.icon}
          </a>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
